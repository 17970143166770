@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,700&family=Jost:wght@400;700&display=swap');

:root {
    --color-primary: #111111;
    --font-color: #EFEAD8;
}

#root {
    /* scroll-snap-type: y mandatory;
    height: 100vh; */
    /* overflow: scroll; */
    background: var(--color-primary);
    position: relative;
}

@media (min-width: 1350px) {
    #main-content, #legal-content {
        margin-left: 20%;
        margin-right: 20%;
    }

    .splashImg-2 {
        background-position: 0% 15% !important;
    }
}

#menuList, #footer {
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
}

.menu-home-ul li {
    list-style: none;
}

h3, h2 {
    font-family: 'Cormorant Garamond', serif !important;
    font-style: italic;
}

p {
    font-family: 'Jost', sans-serif;
}

.category-link {
    font-family: 'Jost', sans-serif;
}

.splashscreen {
    scroll-snap-align: start;
    height: 400px;
    overflow: hidden;
    position: relative;
}

.logo {
    position: absolute;
    /* background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-image: url('./img/logo.png'); */
    filter: brightness(200%);
    mix-blend-mode: lighten;
    pointer-events: none;
    transform: translateY(-30px);
}

div[class^="splashAll"] {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;
    opacity: 0.8;
}

.splashImg-0 {
    background-image: url('./img/airplane-airport.jpeg');
    background-position: 62% 10% !important;
}

/* Media query para ajustar la primera imagen del carrusel a formato móvil */
@media (max-width: 1350px) {
    .splashImg-1 {
        background-image: url('./img/airplane-flying.jpeg') !important;
    }
}

.splashImg-1 {
    background-image: url('./img/airplane-flying-2.jpg');
}

.splashImg-2 {
    background-image: url('./img/champagne.jpeg');
}

img {
    width: auto;
    height: 250px;
}

.imgs {
    width: 38px;
    height: auto;
}

/* Bajar muy poco las banderas */
.banderas {
    padding-top: 1px;
}

.autoplayswiper {
    position: absolute;
    width: 240px;
    right: 0;
    top: 20vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62));
    clip-path: polygon(0 3%, 100% 0, 100% 100%, 0 85%);
    padding-bottom: 16px;
}

.featuredText {
    color: white;
    font-family: 'Jost', sans-serif;
}

.featuredSlider {
    height: 11vh;
}

.swiper-slider {
    color: white !important;
}
.categories-slider {
    /* height: 20vh; */
    position: sticky; 
    top: 0;
    background-color: var(--color-primary);
    
    /* scroll-snap-align: start; */
}
.menu-list {
    /* height: 80vh; */
    overflow: scroll;
}
.visible{
    background-color: aliceblue;
}
.swiper-pagination-bullet-active{
    background: var(--font-color) !important
}
.swiper-pagination-bullet{
    background: var(--font-color) !important
}

.footer {
    color: var(--font-color);
    font-family: 'Jost', sans-serif;

    /* font-family: 'Cormorant Garamond', serif !important;
    font-style: italic; */
}

.legal {
    color: var(--font-color);
    border: 2px solid var(--font-color);
    font-family: 'Jost', sans-serif;
}

.legal-link {
    color: var(--font-color) !important;
    font-family: 'Jost', sans-serif;
}

.botonAtras {
    border: 0;
    color: var(--font-color);
    font-size: calc(80% + 1vw + 1vh);
    background-color: var(--color-primary);
}

.phone {
    font-size: 18px;
    color: var(--font-color);
}

.phone::before {
    padding-bottom: 3px;
}

.a-phone {
    text-decoration: none;
    color: var(--font-color);
}

.a-phone:hover {
    color: var(--font-color);
}

/*
@media (max-width: 992px) {
    .botonAtras {
        font-size: calc(100% + 1vw + 1vh);
    }
}
*/