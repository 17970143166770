.categories-slider{
    color: var(--font-color);
    border: 2px solid var(--font-color);
    border-bottom: none;
    z-index: 10;
}
.categories-slider a, .categories-slider a:visited{
    text-decoration: none;
    color: var(--font-color);
}
