.menu-list{
    color: var(--font-color);
    border: 2px solid var(--font-color);
}

.category-element {
    border-bottom: 1px solid var(--font-color);
}

.category-img {
    height: 120px;
    background-color: white;
    border: 0;
    opacity: 0.9;
}

/* Images */

.img-entradas {
    background-image: url('../img/entrantes.jpg');
    background-size: cover;
    background-position: 20% 56%;
}

.img-ensaladas {
    background-image: url('../img/ensaladas.jpg');
    background-size: cover;
    background-position: 20% 48%;
}

.img-hamburguesas {
    background-image: url('../img/hamburguesas.jpg');
    background-size: cover;
    background-position: 20% 68%;
}

.img-pokes-bowl {
    background-image: url('../img/pokes-bowl.jpg');
    background-size: cover;
    background-position: 20% 38%;
}

.img-pescados {
    background-image: url('../img/pescados.jpg');
    background-size: cover;
    /* background-position: 20% 68%; */
    background-position: 20% 82%;

}

.img-carnes {
    background-image: url('../img/carnes.jpg');
    background-size: cover;
    /* background-position: 20% 76%; */
    background-position: 20% 86%;

}

.img-acompañamientos {
    background-image: url('../img/acompañamientos.jpg');
    background-size: cover;
    background-position: 20% 38%;
}

.img-arroces {
    background-image: url('../img/paellas.jpg');
    background-size: cover;
    background-position: 20% 46%;
}

.img-cremas-y-sopas {
    background-image: url('../img/cremas-y-sopas.jpg');
    background-size: cover;
    background-position: 20% 62%;
}

.img-pasta {
    background-image: url('../img/pasta.jpg');
    background-size: cover;
    background-position: 20% 16%;
}

.img-salsas {
    background-image: url('../img/salsas.jpg');
    background-size: cover;
    background-position: 20% 44%;
}

.img-zumos-naturales {
    background-image: url('../img/zumos.jpg');
    background-size: cover;
    background-position: 20% 34%;
}

.img-postres {
    background-image: url('../img/postres.jpg');
    background-size: cover;
    background-position: 20% 58%;
}

.img-breakfast {
    background-image: url('../img/desayunos.jpg');
    background-size: cover;
    background-position: 20% 60%;
}

.img-bebidas {
    background-image: url('../img/bebidas.jpg');
    background-size: cover;
    background-position: 20% 84%;
}

.img-cervezas {
    background-image: url('../img/cervezas.jpg');
    background-size: cover;
    background-position: 20% 5%;
}

.img-panaderia {
    background-image: url('../img/pan.jpg');
    background-size: cover;
    background-position: 20% 24%;
}

.img-vinos {
    background-image: url('../img/vinos.jpg');
    background-size: cover;
    background-position: 20% 40%;
}

.img-kit-decoracion-cajitas {
    background-image: url('../img/decoracion.jpg');
    background-size: cover;
    background-position: 20% 28%;
}

.img-tripulacion {
    background-image: url('../img/tripulacion.jpg');
    background-size: cover;
    background-position: 20% 100%;
}
